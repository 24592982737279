import React from "react";
import { Link } from "react-router-dom";
import "./FooterStyle.css";
import LogoWhatsApp from "../../assets/logo-whatsapp.webp";
import LogoFacebook from "../../assets/logo-facebook.webp";
import LogoInstagram from "../../assets/logo-instagram.webp";
import LogoLinkedin from "../../assets/logo-linkedin.webp";
import LogoTikTok from "../../assets/logo-tiktok.webp";

const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <div className="footer-content">
          <span className="copyright">
            Copyright © 2023-2025 Webgencia. <br className="rights-reserved" />
            Tous droits réservés.
          </span>
          <div className="links-footer">
            <Link
              to="/mentions-legales"
              className="footer-link first-link-footer"
            >
              Mentions légales
            </Link>
            |
            <Link
              to="/politique-de-confidentialite"
              className="footer-link link-footer"
            >
              Politique de confidentialité
            </Link>
          </div>
        </div>
        <div className="social-networks">
        <a
            href="https://wa.me/+33783775919"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="logo-social-networks logo-whatsapp"
              src={LogoWhatsApp}
              alt="WhatsApp"
            />
          </a>
          <a
            href="https://www.facebook.com/webgencia"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="logo-social-networks logo-facebook"
              src={LogoFacebook}
              alt="Facebook"
            />
          </a>
          <a
            href="https://www.instagram.com/webgencia/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="logo-social-networks logo-instagram"
              src={LogoInstagram}
              alt="Instagram"
            />
          </a>
          <a
            href="https://www.linkedin.com/in/nc-webgencia"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="logo-social-networks logo-linkedin"
              src={LogoLinkedin}
              alt="Linkedin"
            />
          </a>
          <a
            href="https://www.tiktok.com/@webgencia"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="logo-social-networks"
              src={LogoTikTok}
              alt="TikTok"
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
